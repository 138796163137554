import React from "react"
import PropTypes from "prop-types"
import ReviewStars from './ReviewStars';
import { getSlider } from 'simple-slider';

const divideArrIntoPieces = (arr, n) => {
  const arrList = [];
  let idx = 0;
  while(idx < arr.length){
    arrList.push(arr.splice(idx,idx+n));
  }
  return arrList;
}

class RelatedBookSlider extends React.Component {
  constructor(props) {
    super(props);
    const {books, images} = props;
    this.state = {
      books: divideArrIntoPieces(books, 5),
      images: images,
      slider: null,
      isSwipeLeft: true,
    };
  }

  componentDidMount(){
    const slider = getSlider({
      container: document.getElementById('slider'),
    });
    this.setState({slider: slider});
  }

  prevSlide(){
    const slider = this.state.slider;
    if (this.state.isSwipeLeft) {
      this.setState({isSwipeLeft: false})
      slider.reverse();
    }
    slider.next();
  }

  nextSlide(){
    const slider = this.state.slider;
    if (!this.state.isSwipeLeft) {
      this.setState({isSwipeLeft: true})
      slider.reverse();
    }
    slider.next();
  }

  render () {
    return (
      <React.Fragment>
      <ul id="slider">
        {
          this.state.books.map((bookList, index) =>
            <li key={index}>
              {
                bookList.map((book, i) =>
                  <a href={`/books/${book.id}`} key={i}>
                    <dl>
                      <dd className="img">
                        <img src={book.image_url ? book.image_url : this.state.images.no_image} alt={book.title}/>
                      </dd>
                      <dt>{book.title}</dt>
                      <dd className="star">
                        <ReviewStars book={book} star_1={this.state.images.star_1} star_0={this.state.images.star_0} star_05={this.state.images.star_05}/>
                      </dd>
                    </dl>
                  </a>
                )
              }
            </li>
          )
        }
      </ul>
      <div id="slide-swiper">
        <div id="swipe-prev" onClick={this.prevSlide.bind(this)}>&lt;</div>
        <div id="swipe-next" onClick={this.nextSlide.bind(this)}>&gt;</div>
      </div>
      </React.Fragment>
    );
  }
}

export default RelatedBookSlider
