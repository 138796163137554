import React from "react"
import PropTypes from "prop-types"
import { Link } from 'react-router-dom';
import ReviewStars from './ReviewStars';

class BookList extends React.Component {
  constructor(props) {
    super(props);
    const {books, images, isCategory} = props;
    this.state = {
      books: books,
      images: images,
      isCategory: isCategory,
    };
  }

  componentDidUpdate(prevProps){
    if (this.props !== prevProps) {
      this.setState({ books: this.props.books })
    }
  }

  render () {
    const excerptClass = this.state.isCategory ? "bookExcerpt category" : "bookExcerpt";
    const bookExcerpt = (book) => (
      <li key={book.id}>
      {
        book.status === 'opened' ? (
          <a className={excerptClass} href={`/books/${book.id}`}>
            {bookImage(book)}
            {bookDetail(book)}
          </a>
        ) : (
          <div className={excerptClass} href={`/books/${book.id}`}>
            {bookImage(book)}
            {bookDetail(book)}
          </div>
        )
      }
      </li>
    )
    const bookDetail = (book) => (
      <dl className="bookDetail" style={book.status === 'opened' ? {} : {background: 'none'}}>
        <dt className="title">{book.title}</dt>
        <dd className="author">{book.author_name}</dd>
        <dd className="star">
          <ReviewStars book={book} star_1={this.state.images.star_1} star_0={this.state.images.star_0} star_05={this.state.images.star_05}/>
        </dd>
        <dd className="text">{book.description}</dd>
      </dl>
    )
    const bookImage = (book) => (
      <img src={book.image_url ? book.image_url+'?_ex=200x200' : this.state.images.no_image} alt={book.title}/>
    )
    return (
      <React.Fragment>
        <ul>
          {
            this.state.books.map((book,i) =>
              bookExcerpt(book)
            )
          }
        </ul>
      </React.Fragment>
    );
  }
}

export default BookList
