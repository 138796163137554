import React from "react"

class TagInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTags: props.defaultTags || [],
      tags: props.tags || [],
      searchedTags: [],
      tagName: '',
    };
  }

  componentDidMount(){
  }

  handleChangeInput(str) {
    const newTags = (str === '') ? (
      []
    ) : (
      this.state.tags.filter((tag) => tag.name.indexOf(str) > -1)
    )

    this.setState({
      ...this.state,
      searchedTags: newTags,
      tagName: str,
    })

    return newTags
  }

  handleAddTag(e, tag) {
    e.preventDefault()

    if (this.state.selectedTags.find(t => t.id === tag.id)) return

    this.setState({
      ...this.state,
      selectedTags: [...this.state.selectedTags, tag]
    })
  }

  handleRemoveTag(e, tag) {
    e.preventDefault()

    const newTags = this.state.selectedTags.reduce((x, t) => {
      t.id !== tag.id && x.push(t)
      return x
    }, [])

    this.setState({
      ...this.state,
      selectedTags: newTags,
    })
  }

  handleAddNewTag(e) {
    e.preventDefault()

    if (this.state.tagName === '') return

    const tmpId = Math.random().toString(32).substring(2)
    this.setState({
      ...this.state,
      selectedTags: [...this.state.selectedTags, {id: tmpId, name: this.state.tagName}]
    })
  }

  render () {
    return (
      <React.Fragment>
        <ul>
          {
            this.state.selectedTags.map((tag) => (
              <li key={tag.id}>
                {tag.name}
                <button onClick={(e) => this.handleRemoveTag(e, tag)}>削除</button>
              </li>
            ))
          }
        </ul>
        <input value={this.state.selectedTags.map(tag => tag.name).join(',')} className="form-control" id="tagList" type="hidden" name="book_with_tag[tag_list]" readOnly></input>
        <input value={this.state.tagName} onChange={(e) => this.handleChangeInput(e.target.value)}></input>
        <button onClick={(e) => this.handleAddNewTag(e)}>追加</button>
        <ul>
          {
            this.state.searchedTags.map((tag) => (
              <li key={tag.id}>
                {tag.name}
                <button onClick={(e) => this.handleAddTag(e, tag)}>追加</button>
              </li>
            ))
          }
        </ul>
      </React.Fragment>
    );
  }
}

export default TagInput
