import React from "react"
import PropTypes from "prop-types"
class Pager extends React.Component {
  constructor(props) {
    super(props);
    const {pagy} = props;
    this.state = {
      pagy: pagy,
    };
  }

  render () {
    const pagy = this.state.pagy;
    return (
      <React.Fragment>
        <ul className="pager">
          <li className={pagy.page === 1 ? 'none' : ''}>
            <a href={pagy.first_url}>
              <span>&lt; 最初</span>
            </a>
          </li>
          <li>
            <a href={pagy.prev_url} className={pagy.from === pagy.page ? 'button none' : 'button'}>
              <span>前の20件へ</span>
            </a>
          </li>
          <li>
            {pagy.page}/{pagy.pages}
          </li>
          <li>
            <a href={pagy.next_url} className={pagy.last === pagy.page ? 'button none' : 'button'}>
              <span>次の20件へ</span>
            </a>
          </li>
          <li className={pagy.last === pagy.page ? 'none' : ''}>
            <a href={pagy.last_url}>
              <span>最後 &gt;</span>
            </a>
          </li>
        </ul>
      </React.Fragment>
    );
  }
}

export default Pager
