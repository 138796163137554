import React from "react"
import PropTypes from "prop-types"
import BookList from './BookList';


class Tab extends React.Component {
  constructor(props) {
    super(props);
    const {categories, bookMap} = props;
    this.state = {
      categories: categories,
      category: categories.find(c => c.slug === 'best_seller'),
      books: [],
    };
  }

  componentDidMount(){
    this.setState(
      { books: this.props.bookMap[this.state.category.slug] }
    )
  }

  handleChangeCategory(slug, e){
    e.preventDefault();

    this.setState(
      {
        books: this.props.bookMap[slug],
        category: this.state.categories.find(c => c.slug === slug)
      }
    )
  }

  render () {
    const category = this.state.category;
    return (
      <React.Fragment>
        <ul id="bestsellerTab" className={`line${category.id}`}>
          {
            this.state.categories.map((c, i) =>
              <li key={i}>
                <a
                  href="#"
                  className={c.slug == category.slug ? `tab${i+1} tabActive` : `tab${i+1}`}
                  onClick={this.handleChangeCategory.bind(this, c.slug)}
                >
                  <h3>{c.name}</h3>
                </a>
              </li>
            )
          }
        </ul>
        <div className="wrapper">
          <BookList books={this.state.books} category={category} images={this.props.images} />
          <ul className="asideButton">
            <li>
              <a href={`/${category.slug}`} className={category.slug}>{category.name}の本をもっと見る</a>
            </li>
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default Tab
