import React from "react"
import PropTypes from "prop-types"
import Modal from 'react-modal'
import {reduce} from 'lodash'
Modal.setAppElement('#searchBook');

class BookSearchModal extends React.Component {
  constructor(props) {
    super(props);
    const {isOpen, url, params} = props;
    this.state = {
      isOpen: isOpen,
      params: { category: 'best_seller', title_like: '', ...params },
      url: url,
    };
  }

  openModal(e){
    e.preventDefault()
    this.setState({isOpen: true})
  }
  handleChange(e){
    this.setState(
      { ...this.state, params: { ...this.state.params, [e.target.name]: e.target.value } }
    )
  }
  clearBind(){
    this.setState({ params: { category: 'best_seller', title_like: '' } })
  }
  closeModal(){
    this.setState({isOpen: false})
  }
  paramsToSearchUrl(){
    const params = reduce(this.state.params, (x, v, k) => {
      x.push(`${k}=${v}`)
      return x
    }, []).join('&')
    return 'books?' + params
  }

  render () {
    const params = this.state.params;
    return (
      <React.Fragment>
        <a href="" onClick={this.openModal.bind(this)}>絞り込み</a>
        <Modal
          isOpen={this.state.isOpen}
        >
          <div>絞り込み条件</div>

          <div>
            <label>タイトル</label>
            <input
              type="text"
              name="title_like"
              value={params.title_like}
              onChange={this.handleChange.bind(this)}
            />
          </div>

          <div>
            <p>カテゴリ</p>
            <label>ベストセラー</label>
            <input
              type="radio"
              name="category"
              value='best_seller'
              checked={params.category === 'best_seller'}
              onChange={this.handleChange.bind(this)}
            />
            <label>直木賞</label>
            <input
              type="radio"
              name="category"
              value='naoki_prize'
              checked={params.category === 'naoki_prize'}
              onChange={this.handleChange.bind(this)}
            />
            <label>芥川賞</label>
            <input
              type="radio"
              name="category"
              value="akutagawa_prize"
              checked={params.category === 'akutagawa_prize'}
              onChange={this.handleChange.bind(this)}
            />
          </div>

          <div>
            <p>著者の性別</p>
            <label>男性</label>
            <input
              type="radio"
              name="author_sex"
              value={1}
              checked={params.author_sex === '1'}
              onChange={this.handleChange.bind(this)}
            />
            <label>女性</label>
            <input
              type="radio"
              name="author_sex"
              value={2}
              checked={params.author_sex === '2'}
              onChange={this.handleChange.bind(this)}
            />
            <label>男女共作</label>
            <input
              type="radio"
              name="author_sex"
              value={3}
              checked={params.author_sex === '3'}
              onChange={this.handleChange.bind(this)}
            />
          </div>
          <a href={this.paramsToSearchUrl()}>
            絞り込む
          </a>
          <button onClick={this.clearBind.bind(this)}>クリア</button>
          <button onClick={this.closeModal.bind(this)}>キャンセル</button>
        </Modal>
      </React.Fragment>
    );
  }
}

export default BookSearchModal
