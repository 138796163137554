import React from "react"
import PropTypes from "prop-types"
import { times } from 'lodash';
class ReviewStars extends React.Component {
  constructor(props) {
    super(props);
    const {book, star_1, star_0, star_05} = props;
    this.state = {
      book: book,
      star_1: star_1,
      star_0: star_0,
      star_05: star_05,
    };
  }

  star05(decimal) {
    return decimal >= 0.5 ? 1 : 0;
  }

  render () {
    const review_average = this.state.book.review_average || 0;
    const star_1_cnt = Math.floor(review_average);
    const decimal = review_average - star_1_cnt;
    const star_0_5_cnt = this.star05(decimal);
    const star_0_cnt = 5 - (star_1_cnt + star_0_5_cnt);
    return (
      <React.Fragment>
        { times(star_1_cnt, (i) => <img key={i} src={this.state.star_1} alt="星1つ"/>) }
        { times(star_0_5_cnt, (i) => <img key={i} src={this.state.star_05} alt="星0.5"/>) }
        { times(star_0_cnt, (i) => <img key={i} src={this.state.star_0} alt="星0"/>) }
      </React.Fragment>
    );
  }
}

export default ReviewStars
