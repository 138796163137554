import React from "react";
import PropTypes from "prop-types";
import { getSlider } from 'simple-slider';

const divideArrIntoPieces = (arr, n) => {
  const arrList = [];
  let idx = 0;
  while(idx < arr.length){
    arrList.push(arr.splice(idx,idx+n));
  }
  return arrList;
}

class Slider extends React.Component {
  constructor(props) {
    super(props);
    const {books, path} = props;
    this.state = {
      books: divideArrIntoPieces(books, 5),
      path: path,
      slider: null,
      isSwipeLeft: true,
    };
  }

  componentDidMount(){
    const slider = getSlider({
      container: document.getElementById('slider'),
    });
    this.setState({slider: slider});
  }

  prevSlide(){
    const slider = this.state.slider;
    if (this.state.isSwipeLeft) {
      this.setState({isSwipeLeft: false})
      slider.reverse();
    }
    slider.next();
  }

  nextSlide(){
    const slider = this.state.slider;
    if (!this.state.isSwipeLeft) {
      this.setState({isSwipeLeft: true})
      slider.reverse();
    }
    slider.next();
  }

  render () {
    return (
      <React.Fragment>
        <div id="slider">
          {
            this.state.books.map((bookList, index) =>
              <li key={index}>
                {
                  bookList.map((book, i) =>
                    <a href={this.state.path + '/' + book.id} key={i}>
                      <img src={book.image_url+'?_ex=200x200'}/>
                    </a>
                  )
                }
              </li>
            )
          }
        </div>
        <div id="slide-swiper">
          <div id="swipe-prev" onClick={this.prevSlide.bind(this)}>&lt;</div>
          <div id="swipe-next" onClick={this.nextSlide.bind(this)}>&gt;</div>
        </div>
      </React.Fragment>
    );
  }
}

export default Slider
